<template>
  <div class="wrapper">
    <close-icon class="icon-close" @click.native="closeDemo" />
    <div v-if="activeStep === 'step-one'" class="step-one">
      <p class="step-one__title">{{ $t('demoTourTranslater.title') }}</p>
      <p class="step-one__text">
        {{ $t('demoTourTranslater.text') }}
      </p>
      <button type="button" class="btn" @click="stepTwo">
        {{ $t('demoTourTranslater.next') }}
      </button>
    </div>
    <div v-if="activeStep === 'step-two'" class="step-two box">
      <img src="./assets/preview.png" alt="preview" />
      <p class="box__watch-video">
        {{ $t('demoTourTranslater.watchVideoYouTube') }}
      </p>
      <div class="box__link">
        <youtube-icon />
        <a
          href="https://www.youtube.com/watch?v=3WJjEJnFfeY"
          target="_blank"
          class="link__text"
          >{{ $t('demoTourTranslater.video') }}</a
        >
      </div>
      <button type="button" class="btn w-full" @click="stepThree">
        Далее
        <span class="step-btn">
          1/5
          <arrow-icon />
        </span>
      </button>
    </div>
    <div v-if="activeStep === 'step-three'" class="step step-three">
      <div class="box">
        <img src="./assets/preview.png" alt="preview" />
        <p class="box__watch-video">
          {{ $t('demoTourTranslater.watchVideoYouTube') }}
        </p>
        <div class="box__link">
          <youtube-icon />
          <p class="link__text">{{ $t('demoTourTranslater.interface') }}</p>
        </div>
        <button type="button" class="btn w-full" @click="stepFour">
          {{ $t('demoTourTranslater.next') }}
          <span class="step-btn">
            2/5
            <arrow-icon />
          </span>
        </button>
      </div>
      <div class="module"></div>
      <p class="module-text text">{{ $t('demoTourTranslater.modules') }}</p>
    </div>
    <div v-if="activeStep === 'step-four'" class="step step-four">
      <div class="box">
        <img src="./assets/preview.png" alt="preview" />
        <p class="box__watch-video">
          {{ $t('demoTourTranslater.watchVideoYouTube') }}
        </p>
        <div class="box__link">
          <youtube-icon />
          <p class="link__text">{{ $t('demoTourTranslater.interface') }}</p>
        </div>
        <button type="button" class="btn w-full" @click="stepFive">
          {{ $t('demoTourTranslater.next') }}
          <span class="step-btn">
            3/5
            <arrow-icon />
          </span>
        </button>
      </div>
      <div class="modules">
        <div class="module"></div>
        <p class="module-text text">{{ $t('demoTourTranslater.modules') }}</p>
      </div>
      <div class="searchs">
        <div class="search"></div>
        <p class="module-text text">{{ $t('demoTourTranslater.search') }}</p>
      </div>
    </div>
    <div v-if="activeStep === 'step-five'" class="step step-four">
      <div class="box">
        <img src="./assets/preview.png" alt="preview" />
        <p class="box__watch-video">
          {{ $t('demoTourTranslater.watchVideoYouTube') }}
        </p>
        <div class="box__link">
          <youtube-icon />
          <p class="link__text">{{ $t('demoTourTranslater.interface') }}</p>
        </div>
        <button type="button" class="btn w-full" @click="stepSix">
          {{ $t('demoTourTranslater.next') }}
          <span class="step-btn">
            4/5
            <arrow-icon />
          </span>
        </button>
      </div>
      <div class="modules">
        <div class="module"></div>
        <p class="module-text text">{{ $t('demoTourTranslater.modules') }}</p>
      </div>
      <div class="searchs">
        <div class="search"></div>
        <p class="module-text text">{{ $t('demoTourTranslater.search') }}</p>
      </div>
      <div class="maps">
        <div class="map"></div>
        <p class="module-text text">{{ $t('demoTourTranslater.workMap') }}</p>
      </div>
    </div>
    <div v-if="activeStep === 'step-six'" class="step step-four">
      <div class="box">
        <img src="./assets/preview.png" alt="preview" />
        <p class="box__watch-video">
          {{ $t('demoTourTranslater.watchVideoYouTube') }}
        </p>
        <div class="box__link">
          <youtube-icon />
          <p class="link__text">{{ $t('demoTourTranslater.interface') }}</p>
        </div>
        <button type="button" class="btn w-full" @click="finishTour">
          {{ $t('demoTourTranslater.next') }}
          <span class="step-btn">
            5/5
            <arrow-icon />
          </span>
        </button>
      </div>
      <div class="modules">
        <div class="module"></div>
        <p class="module-text text">{{ $t('demoTourTranslater.modules') }}</p>
      </div>
      <div class="searchs">
        <div class="search"></div>
        <p class="module-text text">{{ $t('demoTourTranslater.search') }}</p>
      </div>
      <div class="maps">
        <div class="map"></div>
        <p class="module-text text">{{ $t('demoTourTranslater.workMap') }}</p>
      </div>
      <div class="users">
        <div class="user"></div>
        <p class="module-text text">
          {{ $t('demoTourTranslater.link') }}
          <img src="./assets/developers.png" alt="developers" />
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import CloseIcon from './components/CloseIcon'
import YoutubeIcon from './components/YouTubeIcon'
import ArrowIcon from './components/ArrowIcon'
import { Metrika } from '@/services/metrika/metrika'

export default {
  name: 'DemoTour',
  components: {
    CloseIcon,
    YoutubeIcon,
    ArrowIcon
  },
  data() {
    return {
      activeStep: 'step-one'
    }
  },
  methods: {
    closeDemo() {
      this.$emit('close')
    },
    stepTwo() {
      Metrika.Send('DEMO_STEP_1')
      this.activeStep = 'step-two'
    },
    stepThree() {
      Metrika.Send('DEMO_STEP_2')
      this.activeStep = 'step-three'
    },
    stepFour() {
      Metrika.Send('DEMO_STEP_3')
      this.activeStep = 'step-four'
    },
    stepFive() {
      Metrika.Send('DEMO_STEP_4')
      this.activeStep = 'step-five'
    },
    stepSix() {
      Metrika.Send('DEMO_STEP_5')
      this.activeStep = 'step-six'
    },
    finishTour() {
      this.$emit('close')
    }
  }
}
</script>

<style lang="stylus" scoped>
.wrapper
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.7);
  z-index 10
  .icon-close
    position absolute
    top 44px
    left 50%
    transform: translate(-50%, -50%)
    cursor pointer
  .step-one
    position absolute
    top 50%
    left 50%
    transform translate(-50%, -50%)
    text-align: center;
    width 586px
    &__title
      margin-bottom 16px
      color #A3DEFF
      font-weight: 700;
      font-size: 32px;
      line-height: 120%;
    &__text
      margin-bottom 40px
      font-weight: 400;
      font-size: 14px;
      line-height: 140%;
      color #fff
  .step-three
    height 545px
    display flex
    align-items center

    .module
      position absolute
      opacity 0.6
      left 5px
      height 545px
      width 63px
      background: #A3DEFF;
      border-radius: 70px;
    .module-text
      color: #A3DEFF;
      ont-style: normal;
      font-weight: 700;
      font-size: 32px;
      line-height: 120%;
    .text
      margin-left 100px
  .step-four
    height 545px
    .modules
      display flex
      align-items center
      height 545px
      .module-text
        margin-left 100px
    .searchs
      position absolute
      top 0
      right 5px
      width 549px
      height 63px
      .module-text
        margin-top: 90px;
        text-align: center;
    .search
      position absolute
      top 8px
      right 5px
      width 549px
      height 63px
      background: #A3DEFF;
      border-radius: 70px;
      opacity 0.6
    .maps
      position absolute
      top 43%
      right 8px
      width: 63px;
      height: 228px;
      opacity 0.6
      display flex
      align-items center
      .map
        position absolute
        width: 63px;
        height: 228px;
        background: #A3DEFF;
        border-radius: 70px;
      .module-text
        position: absolute;
        right: 60px;
        width: 260px;
    .users
      position absolute
      bottom 8px
      left 5px
      width 111px
      height 63px
      .user
        position absolute
        bottom 8px
        left 5px
        width 111px
        height 63px
        background: #A3DEFF;
        opacity 0.6
        border-radius: 70px;
      .module-text
        margin-left: 140px;
        align-items: center;
        display: flex;
        width: 580px
        img
          margin-left 13px

.step
  .module
    position absolute
    opacity 0.6
    left 5px
    height 545px
    width 63px
    background: #A3DEFF;
    border-radius: 70px;
  .module-text
    color: #A3DEFF;
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 120%;
.btn
  background: #388BF1;
  border-radius: 10px;
  padding 15px 70px
  color #fff
  min-width 240px
  position relative
  .step-btn
    position absolute
    top 50%
    transform translate(0px, -50%)
    right 10px
    font-weight: 600;
    font-size: 12px;
    line-height: 120%;
    color #fff
    opacity 0.4

.module-text
  color: #A3DEFF;
  font-weight: 700;
  font-size: 32px;
  line-height: 120%;

.box
  position absolute
  top 50%
  left 50%
  transform translate(-50%, -50%)
  text-align: center;
  width 400px
  height 400px
  background rgba(255, 255, 255, 0.8)
  padding 20px
  border-radius 16px
  &__watch-video
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    color #000
    width 331px
    margin 16px auto
  &__link
    display flex
    justify-content center
    align-items center
    margin-bottom 16px
    .link__text
      font-weight: 600;
      font-size: 16px;
      line-height: 120%;
      color #388BF1
      margin-left 7px
</style>
